nb-spinner {
  background-color: transparent !important;
  cursor: wait;
  z-index: 1039 !important;
}

nb-spinner.has-overlay {
  background-color: rgba(0, 0, 0, 0.1) !important;
  align-items: flex-start;
  padding-top: calc(50vh - 4.75rem + 21.5px);
}