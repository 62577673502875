@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
  src: url('../assets/fonts/Roboto-Light.eot');
	src:
    url('../assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Light.woff') format('woff'),
    url('../assets/fonts/Roboto-Light.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Light.svg') format('svg');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
  src: url('../assets/fonts/Roboto-Regular.eot');
	src:
    url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Regular.svg') format('svg');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
  src: url('../assets/fonts/Roboto-Bold.eot');
	src:
    url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Bold.woff') format('woff'),
    url('../assets/fonts/Roboto-Bold.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Bold.svg') format('svg');
}