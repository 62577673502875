nb-option-list {
  nb-option {
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: 'GenericSansW01-Regular', sans-serif !important;

    nb-checkbox {
      .custom-checkbox {
        background-color: #fff !important;
        border-color: #FD8809 !important;
      }
      
      .custom-checkbox.checked {
        background-color: #FD8809 !important;
      }
    }
  }

  nb-option:hover:not(.disabled),
  nb-option.selected:not(.disabled) {
    color: #FD8809 !important;
  }

  nb-option-group {
    .option-group-title {
      color: #FD8809 !important;
    }
  }
}