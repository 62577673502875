@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/corporate';

$nb-themes: nb-register-theme((
  color-primary-100: #C9F2FB,
  color-primary-200: #94DFF7,
  color-primary-300: #5DBEE7,
  color-primary-400: #3599D0,
  color-primary-500: #0169B2,
  color-primary-600: #005199,
  color-primary-700: #003C80,
  color-primary-800: #002A67,
  color-primary-900: #001E55,
  color-primary-transparent-100: rgba(1, 105, 178, 0.08),
  color-primary-transparent-200: rgba(1, 105, 178, 0.16),
  color-primary-transparent-300: rgba(1, 105, 178, 0.24),
  color-primary-transparent-400: rgba(1, 105, 178, 0.32),
  color-primary-transparent-500: rgba(1, 105, 178, 0.4),
  color-primary-transparent-600: rgba(1, 105, 178, 0.48),
  color-success-100: #CAFDD8,
  color-success-200: #96FBBB,
  color-success-300: #61F3A5,
  color-success-400: #39E79B,
  color-success-500: #00D78F,
  color-success-600: #00B88D,
  color-success-700: #009A85,
  color-success-800: #007C77,
  color-success-900: #006167,
  color-success-transparent-100: rgba(0, 215, 143, 0.08),
  color-success-transparent-200: rgba(0, 215, 143, 0.16),
  color-success-transparent-300: rgba(0, 215, 143, 0.24),
  color-success-transparent-400: rgba(0, 215, 143, 0.32),
  color-success-transparent-500: rgba(0, 215, 143, 0.4),
  color-success-transparent-600: rgba(0, 215, 143, 0.48),
  color-info-100: #D6FDFE,
  color-info-200: #ADF7FD,
  color-info-300: #83E8F9,
  color-info-400: #63D5F3,
  color-info-500: #32B8EB,
  color-info-600: #2491CA,
  color-info-700: #196DA9,
  color-info-800: #0F4E88,
  color-info-900: #093770,
  color-info-transparent-100: rgba(50, 184, 235, 0.08),
  color-info-transparent-200: rgba(50, 184, 235, 0.16),
  color-info-transparent-300: rgba(50, 184, 235, 0.24),
  color-info-transparent-400: rgba(50, 184, 235, 0.32),
  color-info-transparent-500: rgba(50, 184, 235, 0.4),
  color-info-transparent-600: rgba(50, 184, 235, 0.48),
  color-warning-100: #FFF3D9,
  color-warning-200: #FFE4B4,
  color-warning-300: #FFD08F,
  color-warning-400: #FFBE73,
  color-warning-500: #FF9F45,
  color-warning-600: #DB7B32,
  color-warning-700: #B75B22,
  color-warning-800: #933F16,
  color-warning-900: #7A2B0D,
  color-warning-transparent-100: rgba(255, 159, 69, 0.08),
  color-warning-transparent-200: rgba(255, 159, 69, 0.16),
  color-warning-transparent-300: rgba(255, 159, 69, 0.24),
  color-warning-transparent-400: rgba(255, 159, 69, 0.32),
  color-warning-transparent-500: rgba(255, 159, 69, 0.4),
  color-warning-transparent-600: rgba(255, 159, 69, 0.48),
  color-danger-100: #FDD8D1,
  color-danger-200: #FCA9A3,
  color-danger-300: #F87478,
  color-danger-400: #F15165,
  color-danger-500: #E91B48,
  color-danger-600: #C8134C,
  color-danger-700: #A70D4C,
  color-danger-800: #870848,
  color-danger-900: #6F0545,
  color-danger-transparent-100: rgba(233, 27, 72, 0.08),
  color-danger-transparent-200: rgba(233, 27, 72, 0.16),
  color-danger-transparent-300: rgba(233, 27, 72, 0.24),
  color-danger-transparent-400: rgba(233, 27, 72, 0.32),
  color-danger-transparent-500: rgba(233, 27, 72, 0.4),
  color-danger-transparent-600: rgba(233, 27, 72, 0.48)
), biz, corporate);

.nb-theme-biz nb-layout .layout {
  background-color: transparent !important;
}

.nb-theme-biz .overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.15) !important;
}