body {
  position: relative !important;
  min-height: 1440px !important;
}

body.new:not(.no-bg) {
  background-color: #333 !important;
}

body.new.white:not(.no-bg){
  background-color: #FAFAFA !important;
}

@import 'fonts';
@import 'themes';

@import '~@nebular/theme/styles/globals';
@import '~@teamhive/ngx-tooltip/assets/styles/styles.css';
@import '~eva-icons/style/scss/eva-icons.scss';

@include nb-install() {
  @include nb-theme-global();
  
  @import './styles/cdk.scss';
  @import './styles/checkbox.scss';
  @import './styles/dialog.scss';
  @import './styles/form-field.scss';
  @import './styles/option-list.scss';
  @import './styles/spinner.scss';
  @import './styles/tooltip.scss';
};

body.new {
  @import './styles/new/tooltip.scss';
  @import './styles/new/option-list.scss';
}