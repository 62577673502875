@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 300;
  src: url('../assets/fonts/RobotoCondensed-Light.eot');
	src:
		url('../assets/fonts/RobotoCondensed-Light.woff') format('woff'),
		url('../assets/fonts/RobotoCondensed-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
  src: url('../assets/fonts/RobotoCondensed-Regular.eot');
	src: 
		url('../assets/fonts/RobotoCondensed-Regular.woff') format('woff'),
		url('../assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
  src: url('../assets/fonts/RobotoCondensed-Bold.eot');
	src: 
		url('../assets/fonts/RobotoCondensed-Bold.woff') format('woff'),
		url('../assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
}