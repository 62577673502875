nb-form-field,
.form-field {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 340px;
  max-width: unset !important;

  > .nb-form-control-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      span.required {
        transition: 0.4s color ease;
      }
      
      span.required.error {
        color: #CF1105;
      }
    }
  }

  nb-icon {
    color: #a1a1a1;
    font-size: 1rem !important;
    height: 2.6rem !important;
    line-height: 2.6rem !important;
    padding: 0 5px;
    z-index: 1;
    text-align: center;
    box-sizing: content-box;
  }

  label {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    color: #87876C;
    font-size: 18px;
    line-height: 24px;
    white-space: nowrap;
  }
  
  input,
  nb-select {
    background-color: #F8F8F8 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100% !important;
    max-width: unset !important;
    height: 36px !important;
    border: 2px solid #A0A4A8 !important;
    border-radius: 7px !important;
    font-size: 16px !important;
    line-height: 24px;
    font-family: 'Roboto';
    font-weight: 700;
    text-align: left;
    color: #3A4453;
    transition: border-color 0.4s ease;
    padding:
      0.5rem
      0
      0.5rem
      10px
      !important;
  }

  textarea {
    background-color: #F8F8F8 !important;
    font-size: 16px !important;
    line-height: 24px;
    border: 2px solid #A0A4A8 !important;
    border-radius: 9px !important;
    height: 104px !important;
    resize: none !important;
    margin-bottom: 23px;
    color: #3A4453;
    transition: border-color 0.4s ease;
    padding:
      0.5rem
      0
      0.5rem
      10px
      !important;
  }

  input.success,
  textarea.success,
  nb-select.success {
    border-color: #3FA300 !important;
  }


  input.error,
  textarea.error,
  nb-select.error {
    border-color: #CF1105 !important;
  }

  .validWeb {
    border-color: #3FA300 !important;
  }

  .invalidWeb {
    border-color: #CF1105 !important;
  }

  .possibleValidWeb {
    border-color: #FD8809 !important;
  }
  .webIcon {
    position: absolute;
    width: 100%;
    top: 35px;
  }
  .webField {
    position: relative;
  }
  div.validWebIcon {
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    content: url("../assets/vectors/checkIcon.svg");
    position: absolute;
    top:10px;
    right: 10px;
  }
  div.spinner{
    position: absolute;
    display: block;
    position: absolute;
    top:5px;
    right: 10px;
  }
  div.invalidWebIcon {
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    content: url("../assets/vectors/xIcon.svg");
    position: absolute;
    top:10px;
    right: 10px;
    }
  div.possibleValidWebIcon {
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    content: url("../assets/vectors/questionIcon.svg");
    position: absolute;
    top:10px;
    right: 10px;
  }

  .character-count {
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    font-size: 11px;
    line-height: 24px;
    font-family: 'Roboto';
    font-weight: 700;
    text-align: end;
    color: #87876C;
  }
  
  .character-count.done {
    color: #47BB00;
  }

  input::placeholder,
  input::-webkit-input-placeholder,
  input::-moz-placeholder,
  input::-ms-input-placeholder,
  input:-moz-placeholder,

  textarea::placeholder,
  textarea::-webkit-input-placeholder,
  textarea::-moz-placeholder,
  textarea::-ms-input-placeholder,
  textarea:-moz-placeholder {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 700 !important;
    font-family: 'Roboto' !important;
    color: #3A4453 !important;
    opacity: 1 !important;
  }

  nb-select {
    padding: 0 !important;

    > button {
      background-color: #F8F8F8 !important;
      flex: 1;
      width: 100%;
      line-height: 18px !important;
      min-width: unset !important;
      max-height: 100%;
      font-weight: 600;
      border: none !important;
      border-radius: 7px !important;
      padding: 8px 15px !important;

      > nb-select-label {
        position: relative;
        top: -1px;
        font-size: 16px;
        height: 20px;
        line-height: 20px;
        font-family: 'Roboto';
        color: #3A4453;
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition:
          color 0.25s ease-in,
          border-color 0.25s ease-in;
      }
        
      > nb-icon {
        width: 25px !important;
        right: 0 !important;
        text-align: center;
        transition: 
          color 0.25s ease-in,
          transform 0.15s ease-in;
      }
    }
  }

  nb-select {
    button {
      padding: 0.5rem 30px 0.5rem 10px !important;

      nb-select-label {
        max-width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  nb-select.align-ignore-arrow {
    > button {
      padding-right: 0 !important;
    }
  }

  nb-select.open {
    border-color: #2fb8eb !important;
    color: #2fb8eb !important;
    box-shadow:
      0 11px 4px rgba(0, 0, 0, 0.1),
      0 3px 3px rgba(0, 0, 0, 0.13);

    > button {
      > nb-select-label {
        color: #2fb8eb !important;
      }

      > nb-icon {
        color: #2fb8eb !important;
      }
    }
  }
}

nb-form-field.disabled-field,
.form-field.disabled-field {
  cursor: not-allowed;

  * {
    pointer-events: none !important;
  }

  nb-select {
    > button {
      > nb-icon {
        opacity: 0.2;
      }
    }
  }
}

nb-form-field.primary-field,
.form-field.primary-field {
  input {
    background-color: #0169B2;
    color: #fff;
  }

  input::placeholder,
  nb-icon {
    color: rgba(255, 255, 255, 0.6);
  }
}

nb-form-field.transparent-field,
.form-field.transparent-field {
  input,
  nb-icon,
  nb-select {
    opacity: 0.3;
  }
}

nb-form-field.percentage-field,
.form-field.percentage-field {
  input {
    padding-left: 0 !important;
    padding-right: 21px !important
  }

  .percentage-sign {
    position: absolute;
    right: 5px;
    font-size: 0.85rem;
  }
}

nb-form-field.toggle-field,
.form-field.toggle-field {
  background-color: transparent;
  border: none !important;

  nb-toggle {
    background-color: transparent !important;
    border: none !important;

    .toggle {
      width: 50px;
      min-width: 50px;
      height: 26px;
      border: 2px solid #0169B2;
      box-sizing: border-box;

      .toggle-switcher {
        margin-top: 2px;
        margin-left: 2px;
        height: 18px;
        width: 18px;
        border: 2px solid #0169B2;
        box-sizing: border-box;

        nb-icon {
          display: none;
        }
      }
    }

    .toggle.checked {
      .toggle-switcher {  
        margin-left: 10px;
        border-color: #fff;
      }
    }

    .text {
      display: block;
      padding: 0 5px;
      font-family: 'Roboto';
      font-size: 18px;
      font-weight: 500;
      color: #333;
      white-space: nowrap;
    }
  }
}

nb-form-field.checkbox-field,
.form-field.checkbox-field {
  nb-checkbox {
    label {
      .text {
        color: #3A4453;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding-left: 20px;
      }
    }
  }
}

nb-form-field.checkbox-field nb-checkbox.hidden,
.form-field.checkbox-field nb-checkbox.hidden {
  display: none;
}

@media only screen and (min-width: 1031px) {
  nb-form-field,
  .form-field {
    > .nb-form-control-container {
      textarea {
        max-width: 340px !important;
      }
    }
  }
}

@media only screen and (max-width: 1030px) {
  nb-form-field,
  .form-field {
    width: 100%;

    input,
    textarea,
    nb-checkbox,
    nb-select,
    nb-toggle {
      max-width: 100% !important;
    }
  }

  nb-form-field.toggle-field,
  .form-field.toggle-field {
    .text {
      font-size: 17px !important;
      line-height: 19px !important;
      white-space: normal !important;
    }
  }
}