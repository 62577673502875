.cdk-drag,
.cdk-drag-preview {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;

  nb-form-field {
    input {
      border-top: none !important;
      border-bottom: none !important;
    }
  }
}

.cdk-drag-handle {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 4px;
  line-height: 0.8rem;
  padding: 3px 3px 2px 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: move;

  nb-icon {
    transform: rotate(90deg);
    font-size: 0.8rem;
    height: 0.8rem;
  }
}

.cdk-drag-handle:hover {
  border-color: #0169B2;

  nb-icon {
    color: #0169B2;
  }
}

.cdk-drag-handle.disabled {
  display: none;
}

.cdk-drag-preview {
  background-color: rgba(0, 0, 0, 0.18);
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.16),
              0 8px 10px 1px rgba(0, 0, 0, 0.15),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  background-color: rgba(0, 0, 0, 0.02);
  border: dotted 2px rgba(0, 0, 0, 0.12);
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}