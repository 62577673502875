@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Assistant-Regular.eot');
	src:
    url('../assets/fonts/Assistant-Regular.woff2') format('woff2'),
    url('../assets/fonts/Assistant-Regular.woff') format('woff'),
    url('../assets/fonts/Assistant-Regular.ttf') format('truetype'),
    url('../assets/fonts/Assistant-Regular.svg') format('svg');
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Assistant-ExtraLight.eot');
	src:
    url('../assets/fonts/Assistant-ExtraLight.woff2') format('woff2'),
    url('../assets/fonts/Assistant-ExtraLight.woff') format('woff'),
    url('../assets/fonts/Assistant-ExtraLight.ttf') format('truetype'),
    url('../assets/fonts/Assistant-ExtraLight.svg') format('svg');
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Assistant-Light.eot');
	src:
    url('../assets/fonts/Assistant-Light.woff2') format('woff2'),
    url('../assets/fonts/Assistant-Light.woff') format('woff'),
    url('../assets/fonts/Assistant-Light.ttf') format('truetype'),
    url('../assets/fonts/Assistant-Light.svg') format('svg');
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Assistant-SemiBold.eot');
	src:
    url('../assets/fonts/Assistant-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Assistant-SemiBold.woff') format('woff'),
    url('../assets/fonts/Assistant-SemiBold.ttf') format('truetype'),
    url('../assets/fonts/Assistant-SemiBold.svg') format('svg');
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Assistant-Bold.eot');
	src:
    url('../assets/fonts/Assistant-Bold.woff2') format('woff2'),
    url('../assets/fonts/Assistant-Bold.woff') format('woff'),
    url('../assets/fonts/Assistant-Bold.ttf') format('truetype'),
    url('../assets/fonts/Assistant-Bold.svg') format('svg');
}

@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Assistant-ExtraBold.eot');
	src:
    url('../assets/fonts/Assistant-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/Assistant-ExtraBold.woff') format('woff'),
    url('../assets/fonts/Assistant-ExtraBold.ttf') format('truetype'),
    url('../assets/fonts/Assistant-ExtraBold.svg') format('svg');
}