nb-option-list {
  border-radius: 7px;
  margin-top: -3px;
  border: none;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.2);

  nb-option {
    background-color: #fff !important;
    padding: 0 5px 0 10px !important;
    font-family: 'Roboto';
    font-size: 14px !important;
    font-weight: 700 !important;
    height: 40px !important;
    line-height: 40px !important;
    display: flex !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  nb-option:hover:not(.disabled),
  nb-option.selected:not(.disabled) {
    background-color: #fff !important;
    background-color: initial !important;
    color: #2fb8eb !important
  }

  nb-option.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  nb-option-group {
    .option-group-title {
      padding-left: 10px !important;
      color: #87876C;
      font-family: 'Roboto Condensed';
      font-size: 1rem;
      border-bottom: 2px solid rgba(111, 112, 92, 0.14);
    }
  }

  nb-option-group:not(:last-child) {
    border-bottom: 2px solid rgba(111, 112, 92, 0.14);
  }
}