biz-dialog {
  nb-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  
    nb-card-footer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      button {
        width: 30%;
      }
    }
  }
}

@media (min-width: 40rem) {
  biz-dialog {
    nb-card {
      width: 38rem;
    }
  }
}