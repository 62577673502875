@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: normal;
  src: local('PT Sans Regular'), url('../assets/fonts/PTSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: bold;
  src: local('PT Sans Bold'), url('../assets/fonts/PTSans-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: bold;
  src: local('PT Sans BoldItalic'), url('../assets/fonts/PTSans-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: normal;
  src: local('PT Sans Italic'), url('../assets/fonts/PTSans-Italic.ttf') format('ttf');
}